<template>
  <div class="cantainer">
    <img src="@/assets/no.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cantainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
